<template>
  <div class="bg">
    <div class="wrapper">
      <template v-if="courseList && courseList.length > 0">
        <Title :title="'相关课程'" />
        <div class="search"><Course :list="courseList" /></div>
      </template>
      <template v-if="courseList && courseList.length > 0">
        <Title :title="'相关讲师'" />
        <div class="search">
          <div class="teacher-container">
            <div class="teacher-item cursor" v-for="(item, index) in courseList" :key="index">
              <div class="img">
                <img style="width: 80px; height: 80px; border-radius: 50%" :src="item" alt="">
              </div>
              <div class="teacher-item-c">
                <div class="floor-t line-text-1">工程造价改革与管理工程造价改革与管理</div>
                <div class="floor-b line-text-3">
                  基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="courseList && courseList.length > 0">
        <Title :title="'相关基地'" />
        <div class="search">
          <div class="jd-container">
            <div class="jd-item cursor" v-for="(item, index) in courseList" :key="index">
              <div class="img">
                <img style="width: 100%; height: 160px; border-radius: 4px 4px 0 0;" :src="item" alt="">
              </div>
              <div class="jd-item-c">
                <div class="floor-t line-text-1">工程造价</div>
                <div class="floor-b line-text-3">
                  基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介基地简介
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- <div v-if="(courseList && courseList.length > 0) && (courseList && courseList.length > 0) && (courseList && courseList.length > 0)" style="margin: 100px 0;"><a-empty /></div> -->
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'
import Course from '@/components/course/course.vue'
import Title from '@/components/title/title.vue'

export default {
  components: { Course, Title },
  setup(props) {
    const route = useRoute()
    const state = reactive({
      courseList: [
        'http://static.roncoos.com/os/11.jpg',
        'http://static.roncoos.com/os/11.jpg',
        'http://static.roncoos.com/os/12.jpg',
        'https://static-dev.roncoo.com/course/587ead2ffb694d1ca1d28e262b54accb.png',
        'https://static-dev.roncoo.com/course/587ead2ffb694d1ca1d28e262b54accb.png'
      ]
    })
    watch(() => route.query.text, (newText, oldText) => {
      console.log('关键字=====' + newText)
    }, { immediate: true })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.bg {
  background: #F7F6FA;
  padding: 20px 0;
  .search {
    margin-top: 14px;
  }
}
.teacher-container {
  display: flex;
  flex-wrap: wrap;
  .teacher-item:not(:nth-child(3n)) {
    margin-right: calc(3% / 2);
  }
  .teacher-item {
    margin-bottom: calc(3% / 2);
    width: 32.3%;
    height: 180px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.1000);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    .img {
      margin-right: 20px;
    }
    .teacher-item-c {
      flex: 1;
      .floor-t {
        font-size: 16px;
        color: #323233;
        margin-bottom: 10px;
      }
      .floor-b {
        font-size: 14px;
        color: #7B7B7B;
        line-height: 26px;
      }
    }
  }
}

.jd-container {
  display: flex;
  flex-wrap: wrap;
  .jd-item:not(:nth-child(4n)) {
    margin-right: calc(4% / 3);
  }
  .jd-item {
    margin-bottom: calc(4% / 3);
    width: 24%;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.1000);
    border-radius: 4px;
    .jd-item-c {
      padding: 8px;
      .floor-t {
        font-size: 16px;
        color: #333;
      }
      .floor-b {
        margin-top: 10px;
        font-size: 14px;
        color: #7B7B7B;
        line-height: 22px;
        height: 66px;
      }
    }
  }
}
</style>
